import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
const routes = [
	{
		path: "/",
		name: "/",
		component: () =>
			import(/* webpackChunkName: "SignIn" */ "../components/signin.vue"),
		meta: { requiresAuth: false },
	},

	{
		path: "/dashboard",
		name: "Dashboard",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/dashboard.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/reports",
		name: "reports",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/reports.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/examreports",
		name: "examreports",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/examreports.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/assignments",
		name: "Assignments",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/assignments.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/selftests",
		name: "Selftests",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/assignments.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/myexams",
		name: "Myexams",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/mainexams.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/ai",
		name: "Ai",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/ai.vue"
			),
		meta: { requiresAuth: true },
	},
	{
		path: "/chat",
		name: "chat",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/ChatModule.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/vodeoclasses",
		name: "vodeoclasses",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/videoclasses.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/buslocation",
		name: "bus",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/bus.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/liveclasses",
		name: "liveclasses",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/liveclasses.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/pastpapers",
		name: "pastpapers",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/pastpapers.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/coding",
		name: "CodingModule",
		component: () => import("../components/Elearning/CodingModule.vue"),
		meta: { requiresAuth: true },
		children: [
			{
				path: "", // /coding
				name: "LearnToCode",
				component: () =>
					import("../components/Elearning/coding/LearnToCode.vue"),
			},
			{
				path: "course/:courseName/:courseId", // /coding/course/:courseName
				name: "CourseDetails",
				component: () =>
					import("../components/Elearning/coding/CourseDetails.vue"),
			},
			{
				path: "editor", // /coding/editor
				name: "Editor",
				component: () =>
					import("../components/Elearning/coding/EditorCode.vue"),
			},
			{
				path: "exercises", // /coding/exercises
				name: "Exercises",
				component: () =>
					import("../components/Elearning/coding/Exercises.vue"),
			},
			{
				path: "exercises/assessment/:language/:type/:index",
				component: import(
					"../components/Elearning/coding/AssessmentView.vue"
				),
				name: "AssessmentView",
			},
			{
				path: "team",
				name: "Team",
				component: () =>
					import(
						/* webpackChunkName: "Hr" */ "../components/Elearning/coding/MyTeam.vue"
					),
				meta: { requiresAuth: true },
			},
		],
	},

	{
		path: "/team-ranking",
		name: "team-ranking",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/coding/TeamRanking.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/notes",
		name: "notes",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/notes.vue"
			),
		meta: { requiresAuth: true },
	},

	{
		path: "/quizzes",
		name: "QuizzesModule",
		component: () => import("../components/Elearning/QuizzesModule.vue"),
		meta: { requiresAuth: true },
		children: [
			{
				path: "active", // /quizzes
				name: "Active Quizzes",
				component: () =>
					import("../components/Elearning/quizzes/ActiveQuizzes.vue"),
			},
			{
				path: "pending", // /quizzes/pending
				name: "Pending Quizzes",
				component: () =>
					import(
						"../components/Elearning/quizzes/PendingQuizzes.vue"
					),
			},
			{
				path: "completed", // /quizzes/completed
				name: "Completed Quizzes",
				component: () =>
					import(
						"../components/Elearning/quizzes/CompletedQuizzes.vue"
					),
			},
		],
	},

	{
		path: "/quiz/:id",
		name: "QuizDetails",
		component: () =>
			import(
				/* webpackChunkName: "Quizzes" */ "../components/Elearning/quizzes/QuizDetails.vue"
			),
		meta: { requiresAuth: true },
		// props: true,
	},

	{
		path: "/books",
		name: "books",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/notes"
			),
		meta: { requiresAuth: true },
	},
	{
		path: "/notifications",
		name: "Notifications",
		component: () =>
			import(
				/* webpackChunkName: "Hr" */ "../components/Elearning/NotificationHandler.vue"
			),
		meta: { requiresAuth: true },
	},
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const element = document.querySelector(to.hash);
      if (element) {
        return { el: to.hash, behavior: 'smooth' }; // Use object syntax for Vue Router
      }
      return { top: 0 }; // Fallback if element not found
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  }
  
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);
  const isAuthenticated = store.getters.isAuthenticated || localStorage.getItem("authToken");

  let modalBackground = document.querySelector('.modal-backdrop')
  if (modalBackground) {
    modalBackground.remove()
  }
  if (authRequired && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
